$(() => {
  /* global Tbk */
  Tbk.alerts.init()
})

Tbk.alerts = {

  // initial top offset of .alerts (used for sticky positioning)
  offset: 0,

  init: () => {
    if (!$('.alerts').length) {
      return
    }

    Tbk.alerts.offset = $('.alerts').offset().top

    $(window).on('scroll', () => {
      Tbk.alerts.sticky()
    })

    $('.alerts .alert').each(function() {
      Tbk.alerts.button(this)
    })

    $('body').on('click', '.alert-close', function() {
      Tbk.alerts.close(this)
    })
  },

  // Create a new alert
  new: (text, level) => {
    let alert, klass

    if (level === null) {
      level = false
    }

    klass = 'alert'

    if (level) {
      klass += ' ' + level
    }

    // eslint-disable-next-line prefer-const
    alert = $('<div/>').addClass(klass)
    text = $('<p/>').text(text)
    // adding id based on Accesibility360 requirments
    text.attr('id', 'err_account')
    alert.append(text)
    alert.appendTo($('.alerts')).slideUp(0)
    alert.slideDown(300)
    Tbk.alerts.button(alert)
  },

  // Add a close button
  button: (element) => {
    $(element).append($('<button/>').addClass('alert-close').html('Close'))
  },

  // Make .alerts fixed position when it reaches the top of the viewport
  sticky: () => {
    const fix = $(window).scrollTop() > Tbk.alerts.offset
    $('.alerts')[fix ? 'addClass' : 'removeClass']('fixed')
  },

  // Close this .alert (or the parent .alert of this element)
  close: (element) => {
    $(element).closest('.alert').slideUp(300)
  }
}
